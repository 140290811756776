#top
  &.land-one
    display: flex
    justify-content: center
    align-items: center
    position: relative
    top: 16px
    left: 16px
    right: 16px
    width: calc(100% - 32px)
    height: 80px
    border-radius: 40px
    background-color: rgba(255,255,255,.95)
    header
      +v-sm
        position: absolute
        border: none
        background-color: transparent !important
        transform: translate(20px,12px) !important
    .burger
      +v-sm
        transform: translate(-30px,10px) !important
        span
          background-color: #451690 !important
    .menu
      +v-sm
        height: 184px !important
        min-height: 184px !important
        max-height: 184px !important
        bottom: auto !important
        background-color: $onetheme
        left: -21px !important
        top: 100px !important
        border-radius: 24px
        transition: ease-in-out .5s
        a
          color: #fff !important
      &.active
        +v-sm
          width: 0 !important
          left: 100% !important
          a
            color: rgba(0,0,0,0) !important
  &.land-two
    display: flex
    justify-content: center
    align-items: center
    position: relative
    top: 0
    left: 0
    right: 0
    width: 100%
    height: 84px
    border-radius: 0 0 24px 24px
    background-color: $white
    box-shadow: 0 2px 10px rgba(0,0,0,.15)
    z-index: 1
    header
      +v-sm
        border: none
        position: absolute
        background-color: transparent !important
        transform: translate(20px,14px) !important
    .burger
      +v-sm
        transform: translate(-40px,10px) !important
        span
          background-color: #451690 !important
      +v-xs
        transform: translate(-20px,10px) !important
    .menu
      +v-sm
        height: 239px !important
        min-height: 239px !important
        max-height: 239px !important
        bottom: auto !important
        background-color: $twotheme
        left: -20px !important
        top: 100px !important
        border-radius: 24px
        transition: ease-in-out .5s
        a
          color: #6A040F !important
          transform: translateX(-16px) !important
          border-top: none !important
          border-bottom: 1px solid darken($twotheme,20%) !important
      &.active
        +v-sm
          width: 0 !important
          left: 100% !important
  &.land-three
    display: flex
    justify-content: center
    align-items: center
    position: relative
    top: 16px
    left: 16px
    right: 16px
    width: calc(100% - 32px)
    height: 80px
    border-radius: 40px
    background-color: #F3EDFF
    header
      +v-sm
        border: none
        position: absolute
        background-color: transparent !important
        transform: translate(20px,14px) !important
    .burger
      +v-sm
        transform: translate(-40px,10px) !important
        span
          background-color: #451690 !important
      +v-xs
        transform: translate(-20px,10px) !important
    .menu
      +v-sm
        height: 239px !important
        min-height: 239px !important
        max-height: 239px !important
        bottom: auto !important
        width: calc(100% - 8px)
        background-color: $twotheme
        left: -16px !important
        top: 100px !important
        border-radius: 24px
        transition: ease-in-out .5s
        a
          color: #6A040F !important
          transform: translateX(-16px) !important
          border-top: none !important
          border-bottom: 1px solid darken($twotheme,20%) !important
      &.active
        +v-sm
          width: 0 !important
          left: 100% !important
