#intro
  &.land-two
    width: 100%
    height: calc(100vh + 80px)
    padding-top: 80px
    margin-top: -80px
    display: flex
    justify-content: center
    align-items: center
    img
      +v-sm
        width: 80%
        height: auto
        margin: 10px auto
    +v-sm
      height: auto
      padding: 120px 0 60px
      *
        text-align: center

.ldntwo
  .m-20
    +v-sm
      height: 20px !important
  .container
    +v-md
      width: 94%
      padding-left: 3%
      padding-right: 3%
      .title.two-title
        margin-top: 30px
      *
        text-align: center !important
    .col-lg-6
      img
        +v-sm
          width: 100%
          margin: 24px 0 10px
          height: auto
          &.up40
            margin-top: -40px !important
      .two-card
        width: 96% !important
        margin-left: auto !important
        margin-right: auto !important
        &.white
          &:first-of-type
            +v-sm
              margin-top: 20px !important

    .accordion
      *
        +v-sm
          text-align: left !important
      +v-sm
        margin-top: -40px !important
      .accordion-body
        +v-sm
          font-size: 0.8em !important
          opacity: .8
.vm
  display: none
  +v-sm
    display: block
.vd
  display: inline-flex
  +v-sm
    display: none

.two
  &-card
    padding: 24px 32px
    background-color: $twocard
    border: 1px solid $twoborder
    &.white
      background-color: $white
      border: 1px solid #E0E0E0
  &-text
    font-size: 1em
    font-family: $two
    line-height: 1.5
    +v-sm
      width: 90%
      margin: 8px auto
  &-title
    font-family: $two !important
    background: linear-gradient(90deg, #451690 0%, #6A040F 100%) !important
    -webkit-background-clip: text !important
    -webkit-text-fill-color: transparent !important
    background-clip: text !important
    text-fill-color: transparent !important

.main
  margin-left: 8px
  +v-sm
    margin-left: 0px

form
  *
    font-family: $two