#intro
  &.land-one
    width: 100%
    height: calc(100vh + 100px)
    padding-top: 100px
    margin-top: -100px
    display: flex
    justify-content: center
    align-items: center
    background: #363062 url('../../assets/one/intro-bg.jpg') center center no-repeat
    background-size: cover
    background-blend-mode: soft-light
    +v-sm
      height: auto
      padding: 160px 0 60px

#about
  &.land-one
    *
      +v-sm
        text-align: center !important
    .m-40
      +v-sm
        height: 40px !important
        min-height: 40px !important
        max-height: 40px !important
    .bg-yellow
      +v-sm
        width: 80%
        margin: 8px auto
#reviews
  &.land-one
    .m-40
      +v-sm
        height: 40px !important
        min-height: 40px !important
        max-height: 40px !important
    .cr-blue
      +v-sm
        width: 90%
        margin: 8px auto
#join
  &.land-one
    *
      +v-sm
        text-align: center !important
    .m-40
      display: none
      +v-sm
        height: 40px !important
        min-height: 40px !important
        max-height: 40px !important
    .col-lg-4
      +v-sm
        max-width: 33.33333% !important
      img
        object-fit: cover
        +v-md
          height: 130px !important
        +v-sm
          height: 120px !important

.one
  &-font
    font-weight: 400
    font-family: $one !important
  &-title
    font-family: $titleone !important
