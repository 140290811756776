@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300..900;1,300..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&family=Inter:wght@100..900&display=swap')

$body: #fff
$theme: #46BCFF
$dark: #444
$white: #fff
$light: #40BAFF11
$medium: #444
$lite: #6e6e6e

$button: $theme

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660
$yellow: #F99417
$black: #000
$basic: sans-serif
$two: 'Lexend', sans-serif
$twotheme: #FFD8B5

$onetheme: #363062
$one: 'Fira Sans', sans-serif
$titleone: 'Playfair Display', serif

$twocard: $twotheme
$twoicon: #612FB1
$twoborder: #FF7A00
$twobtn: #6A040F

$threecolor: #451690
$threetext: #616161

$three: 'Inter', sans-serif
$threetitle: 'Domine', serif

.c-onetheme
  color: $onetheme !important
.c-twotheme
  color: $onetheme !important
.c-black
  color: $black !important
.c-three
  color: $threecolor !important

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
