.title
  margin: 0
  padding: 0
  font-size: 2.7em
  font-weight: 500
  line-height: 1.25
  &-part
    font-size: 1.25em
  &.three-text
    color: $threetext !important
    font-family: $three !important
    font-size: 1.4em !important
    line-height: 1.75 !important
    &.dark
      color: #212121 !important
  &.three-title
    color: $three !important
    font-family: $threetitle !important
    font-size: 3em !important
    font-weight: 400
    line-height: 1.75 !important
    +v-md
      font-size: 2em !important
      line-height: 1.5 !important
    &.mini
      font-size: 2em !important
      line-height: 1.25 !important
      +v-sm
        text-align: center !important
        margin-bottom: 20px
        font-size: 1.75em !important
        text-align: center !important
    &.cards
      font-family: $three !important
      color: #212121 !important
      font-size: 24px !important
      font-weight: 500 !important
      line-height: 1.25 !important
    &.primary
      font-size: 2.2em !important
      color: #6A040F !important
  &-intro
    &.one-title
      font-size: 6em !important
      +v-sm
        font-size: 3em !important
        width: 90% !important
    &.two-title
      font-size: 3em !important
      font-weight: bold
      +v-sm
        font-size: 2.4em !important
    &.three-title
      color: $threecolor !important
      font-family: $threetitle !important
      font-size: 4.7em !important
      font-weight: bold
      +v-sm
        font-size: 2.4em !important
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 0.85em
  &-page
    font-size: 1.15em
    font-weight: 500
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
  &-sub
    font-size: 1.35em
    font-weight: 600
    &.three
      display: inline-block
      color: $threecolor !important
      font-family: $threetext !important
      font-size: .8em !important
      font-weight: 500
      line-height: .8em !important
      transform: translateY(2px) !important
      &.dark
        color: #212121 !important
    &.two
      font-size: .8em
      color: $black
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
    &.w-60
      +v-xs
        margin: 0 auto
        min-width: 96% !important
    &.w-40
      +v-sm
        margin: 0 0 60px
        min-width: 100% !important

footer
  +v-sm
    margin-top: 80px
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important

.ml-10
  margin-left: 10px !important