.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $white !important
  font-size: 1em
  font-weight: 500
  letter-spacing: .05em
  border-radius: 4px
  transition: all ease-out .25s
  &.b-one
    background-color: $yellow
    border: none !important
    outline: none !important
    &:hover
      color: $dark !important
      background-color: $white !important
  &.b-two
    padding: 0 24px
    color: $twobtn !important
    font-weight: 600
    letter-spacing: 0
    border: 2px solid #6A040F !important

    border-radius: 20px
    background-color: $twotheme
    outline: none !important
    width: 100%
  &.b-subscribe
    padding: 0 24px
    color: #6A040F !important
    font-weight: 600
    letter-spacing: 0
    border: none !important

    border-radius: 20px
    background-color: $twoborder
    outline: none !important
    width: 100%
    
    //disable hover for now
    /*&:hover
      color: $white !important
      background-color: $twoborder !important*/
