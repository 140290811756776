#intro
  &.land-three
    width: 100%
    height: calc(100vh + 100px)
    padding-top: 100px
    margin-top: -100px
    display: flex
    justify-content: center
    align-items: center
    background: #fff
    +v-sm
      height: auto
      padding: 140px 0 0
      *
        text-align: center

.ldnthree
  footer
    +v-sm
      margin-top: 0 !important
  .m-20
    width: 0
    height: 0
    display: inline-block
    +v-sm
      width: 100% !important
      display: block !important
      height: 20px !important
  .m-40
    width: 0
    height: 0
    display: inline-block
    +v-sm
      width: 100% !important
      display: block !important
      height: 40px !important
  .hide
    +v-sm
      display: none
  .container
    +v-md
      width: 94%
      padding-left: 3%
      padding-right: 3%
      .title.two-title
        margin-top: 30px
  #about
    .col-lg-4
      +v-sm
        padding-left: 24px
        padding-right: 24px
  #features
    .title
      &.three-text
        +v-sm
          font-size: 1em !important
          line-height: 1.5 !important

img.obfc.r-24.mh-100
  +v-sm
    display: none

#join
  &.three-landing
    background: pink url('../../assets/three/join.png') center center no-repeat
    background-size: cover
#start
  background: url('../../assets/three/get.png') right bottom no-repeat
  background-size: 52%
  +v-sm
    background: none
    a
      display: block
      text-align: center !important

.three
  &-button
    border: none !important
    outline: none !important
  &-font
    color: $threetext !important
    font-family: $three !important
  &-text
    color: $threetext !important
    font-family: $three !important
    font-size: 16px !important
    font-weight: 400
    &.w-80
      +v-sm
        font-size: 1em !important
        text-align: center !important
        width: 100% !important
        margin-bottom: 40px !important
        min-width: 100% !important
        max-width: 100% !important
  &-card
    padding: 24px 32px
    background-color: #FFF1E4
    border: 1px solid $twoborder
